.impressum {
    margin: 55px;
}

.impressum h1 {
    margin-top: 84px;
}

.impressum h2 {
    margin-top: 45px;
    margin-bottom: 25px;
}