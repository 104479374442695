.nutzen_wrapper {
    display: flex;
    flex-direction: row;
    background: linear-gradient(-90deg, rgba(235,95,74,0.7) 0%, rgba(235,95,74,0.8) 45%, rgba(235,95,74,0.9) 100%);
    color: white;
    padding-left: 80px; 
    padding-top: 45px;
    padding-bottom: 45px;
}

.nutzensection_header {
    display: flex;
    padding-top: 75px;
    padding-left: 45px;
    justify-content: center;
    letter-spacing: 1.9px;
    width: 25%; 
    
}

.nutzensection_header h2 {
    font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: 1.8px;
}

.nutzensection_content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 80%;
    padding: 30px 100px 40px;  
}

.nutzensection_card {
    width: 45%;
    padding: 20px;
    text-align: center;
    border: 2px solid white;
    border-radius: 6px;
    margin: 15px; 
    background-color: black;
    transition: all 0.8s ease-in-out;
}

.nutzensection_card_title {
    display: flex;
    flex-direction: column;
    
}
.nutzensection_card_title:after {
    content: "";
    display: block;
    width: 30%;
    height: 1px;
    background-color: rgb(235, 95, 74);
    margin: 1.5rem auto;

}

.nutzensection_card p {
    color:rgb(180, 180, 180);
    
}

.nutzensection_card:hover {
    background-color: rgb(42, 41, 41);
    transform: scale(1.05);
}

.nutzensection_card_favicon {
    height: 50px;
    width: 20px;
}

/*Media Queries*/
@media screen and (max-width: 1200px) {
    .nutzen_wrapper {
        flex-direction: row;
        padding-left: 0px;
        
    }

    .nutzensection_header {
        width: 40%; 
        text-align: center;
        margin: 0 auto;
        padding: 35px;
        
        
    }
    
    .nutzensection_header h2 {
        font-size: 2.5rem;
       
    }

    .nutzensection_content {
        flex-direction: row;
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
        
    }
    
    .nutzensection_card {
        width: 40%;
        height: 380px;
        padding: 20px;
        text-align: center;
        border: 2px solid white;
        border-radius: 6px;
        margin: 15px; 
        background-color: black;
    
    }

    .nutzensection_card_title h4{
        margin-top: 15px;
    }
    
}


@media screen and (max-width: 1000px) {
    .nutzen_wrapper {
        flex-direction: column;
        padding-left: 0px;
        
    }

    .nutzensection_header {
        width: 80%; 
        text-align: center;
        margin: 0 auto;
        padding: 35px;
        
        
    }
    
    .nutzensection_header h2 {
        font-size: 2.5rem;
       
    }

    .nutzensection_content {
        flex-direction: row;
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
        
    }
    
    .nutzensection_card {
        width: 40%;
        height: 320px;
        padding: 20px;
        text-align: center;
        border: 2px solid white;
        border-radius: 6px;
        margin: 15px; 
        background-color: black;
    
    }

    .nutzensection_card_title h4{
        margin-top: 15px;
    }
    
}

@media screen and (max-width: 768px) {
    .nutzen_wrapper {
        flex-direction: column;
        padding-left: 0px;
    }

    .nutzensection_header {
        width: 100%; 
        text-align: center;
        padding: 35px;
        
        
    }
    
    .nutzensection_header h2 {
        font-size: 1.5rem;
    }

    .nutzensection_content {
        flex-direction: row;
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
        
    }
    
    .nutzensection_card {
        width: 40%;
        height: 350px;
        padding: 20px;
        text-align: center;
        border: 2px solid white;
        border-radius: 6px;
        margin: 15px; 
        background-color: black;
    
    }

    .nutzensection_card_title h4{
        margin-top: 15px;
    }
    
}


@media screen and (max-width: 620px) {
    .nutzen_wrapper {
        flex-direction: column;
        padding-left: 0px;
    }

    .nutzensection_header {
        width: 100%; 
        text-align: center;
        padding: 35px;
        
        
    }
    
    .nutzensection_header h2 {
        font-size: 1.5rem;
    }

    .nutzensection_content {
        flex-direction: row;
        width: 100%;
        padding: 0px;
        align-items: center;
        
    }
    
    .nutzensection_card {
        width: 40%;
        height: 400px;
        padding: 20px;
        text-align: center;
        border: 2px solid white;
        border-radius: 6px;
        margin: 15px; 
        background-color: black;
    
    }

    .nutzensection_card_title h4{
        margin-top: 15px;
        font-size: 0.8rem;
    }

    .nutzensection_card p{
        margin-top: 15px;
        font-size: 0.9rem;
        
    }
    
}

@media screen and (max-width: 515px) {
    .nutzen_wrapper {
        flex-direction: column;
        padding-left: 0px;
    }

    .nutzensection_header {
        width: 100%; 
        text-align: center;
        padding: 20px;
        
        
    }
    
    .nutzensection_header h2 {
        font-size: 1.5rem;
    }

    .nutzensection_content {
        flex-direction: column;
        width: 100%;
        padding: 0px;
        align-items: center;
        
    }
    
    .nutzensection_card {
        width: 80%;
        height: auto;
        padding: 20px;
        text-align: center;
        border: 2px solid white;
        border-radius: 6px;
        margin: 15px; 
        background-color: black;
    
    }

    .nutzensection_card_title h4{
        margin-top: 15px;
        font-weight: 700;
    }

    .nutzensection_card p {
        padding-bottom: 25px;
    }

    
}