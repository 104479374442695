.angebot_section_black {
    background-color: rgba(0,0,0,1);
    color: white;
    
  }

  .angebot_wrapper {
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .angebot_section_content{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0% 5%;
    text-align: center;
  }

  .angebot_section_content_title {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: 1.8px;
    
    
  }
  
  .angebot_section_content_title:after {
      content: "";
      display: block;
      width: 30%;
      height: 1px;
      background-color: rgb(235, 95, 74);
      margin: 1.5rem auto;
  
  }

  .angebot_section_content p {
    color:rgb(180, 180, 180);
    
  }

  #angebot_picture {
    height: 100%;
    width: 50%;
    color: black;
    display: flex;
    align-items: center;
    opacity: 0.8; 
    filter: grayscale(100%);
    margin: 0;
    object-fit: cover;
  
  }
  
  .story_section_content_title:after {
    content: "";
    display: block;
    width: 30%;
    height: 1px;
    background-color: rgb(235, 95, 74);
    margin: 1.5rem auto;
}

.angebot_button_black {
  width: 150px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  height: 50px;
  text-align:center;
  letter-spacing: 1.2px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: transparent;
  transition: all .4s ease-in-out;
  margin: 40px 0px;
}


.angebot_button_black:hover {
  border-color: rgb(235, 95, 74);
  color:rgb(235, 95, 74);
  transition: all .4s ease-in-out;
}



@media screen and (max-width: 1024px) {
  .angebot_wrapper {
    flex-direction: column;
    height: auto;
    padding: 0;
  }

  #angebot_picture {
    
    width: 100%;
    height: auto;
  }
  
  .angebot_section_content{
    width: 100%;
    margin-top: 25px;
    padding: 35px;
  }

  .angebot_section_content_title {
    font-size: 2.5rem;
  }
  
}

@media screen and (max-width: 890px) {
  .angebot_wrapper {
    flex-direction: column;
    height: auto;
    padding: 0;
  }

  #angebot_picture {
    
    width: 100%;
    height: auto;
  }
  
  .angebot_section_content{
    width: 100%;
    margin-top: 25px;
    padding: 35px;
  }

  .angebot_section_content_title {
    font-size: 2rem;
  }
  
  
}


@media screen and (max-width: 501px) {
  
  #angebot_picture {
    width: 100%;
    height: auto;
  }

  .angebot_section_content{
    width: 100%;
    margin-top: 15px;
    padding: 35px;
    
  }

}

