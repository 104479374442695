nav {
    position: sticky;
    top: 0; 
    left: 0;
    width: 100%;
    height: 80px;
    background-color: black;
    color: white;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 2%;
    
    
}

.navbar_logo {
    height: 40px;
    width: auto;

}

.navbar_list {
    display: flex;
    list-style: none;
}

.navbar_item {
    white-space: nowrap;
}

.navbar_link {
    font-size: 0.85rem;
    text-decoration: none;
    color: white;
    text-align: center;
    padding: 10px;
    margin: 0px 5px;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: transparent;
    transition: 250ms;

}

.navbar_link:hover {
    border-color: rgba(235, 95, 74, 0.75);
    transition: 850ms;
    color: white;
}

.navbar_menu {
    
    color: white;
    cursor: pointer;
    display: none;
}



@media screen and (max-width: 1024px) {
    .navbar_list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;                         
        top: 80px;
        right: -100%;
        width: 100%;
        height: 200px;
        transition: all 0.3s;
        opacity: 0.8;
        display: none;
    }

    .navbar_list--active {
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;                         
        top: 80px;
        width: 100%;
        height: 200px;
        transition: all 0.3s;
        opacity: 0.8;
       
    }

    .navbar_item {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
    }

    .navbar_link {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 0px;
        background-color: black;
        border-style: none;
    }

    .navbar_link:hover {
        border-bottom: none;
        font-weight: bold;
        color: rgba(235, 95, 74, 1);
    }

    .navbar_menu{
        display: block;
        
    }

    
}

