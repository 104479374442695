/*
-----------------
ÜBER UNS SECTION
-----------------*/


.ueberuns_section_wrapper{
    padding-bottom: 60px;
    background-color: rgba(0, 0, 0, 1);
    color: white;
}

.ueberuns_section_wrapper p {
    color:rgb(180, 180, 180);
}

.section_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 80px 0px 80px;
}

.section_wrapper h2:after {
        content: "";
        display: block;
        width: 30%;
        height: 1px;
        background-color: rgba(235,95,74,1);
        margin: 1rem auto;
}

.section_wrapper h3:after {
    content: "";
        display: block;
        width: 30%;
        height: 1px;
        background-color: rgba(235,95,74,1);
        margin: 1rem auto;
}

#section_wrapper {
    flex-direction: row;
}

.section_wrapper h2 {
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: 1.8px;

}

.section_wrapper h3 {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 1.8px;
}

.section_wrapper p{
    letter-spacing: 1.5px;
}
/*
-----------------
CARDS SECTION
-----------------*/

.cards_wrapper {
    background: linear-gradient(-90deg, rgba(235,95,74,0.7) 0%, rgba(235,95,74,0.8) 45%, rgba(235,95,74,0.9) 100%);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 25px;
}

.card {
    width: 50%;
    padding: 25px;
    text-align: center;
    border: none;
    margin: 35px;
    text-align: left;
    background-color: transparent;
    color: white;
}

.card img {
    width: 60%;
    height: auto;   
}

.card ul {
    margin-top: 55px;
}

.card li {
    list-style: none;
    margin: 1rem;
    letter-spacing: 1.5px;
}

.icon {
    color: black;
    background-color: transparent;
}

.card_content h4 {
    margin-top: 2rem;
}

.card_content h6 {
    letter-spacing: 1.5px;
    margin-bottom: 2rem;
    color:rgb(101, 99, 99)
}

.card_content p {
    letter-spacing: 1.5px;    
}

#button_überuns {
    width: 200px;
    border-radius: 10px;
    border: black;
    background-image: linear-gradient(to right, #202226, #31221e, #868382, #888382);

}


/*
--------------
WERTE SECTION
---------------*/
.werte_cards_wrapper {
    background-color: rgba(0, 0, 0, 0.85);
    color: white;  
}

.werte_content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 25px;
}

.werte_header{
    display: flex;
    padding-top: 75px;
    padding-left: 45px;
    justify-content: center;
    letter-spacing: 1.9px;
}

.werte_header h2 {
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: 1.8px;
}

.werte_header h2:after {
        content: "";
        display: block;
        
        width: 30%;
        height: 1px;
        background-color: rgba(235,95,74,1);
        margin: 1rem auto; 
}

.werte_card {
    width: 35%;
    padding: 20px;
    margin: 15px; 
    text-align: left;
}

.werte_card h5 {
    margin-bottom: 20px;
    font-weight: 700;
    letter-spacing: 1.5px;
    color: rgba(235,95,74,1)
}

.icon {
    height: 60px;
    width: 60px;
    border-radius: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}
.werte_card img {
    max-width: 50%;
    height: 50%;   
}

.werte_card p {
    color:rgb(180, 180, 180);
    
}

/*
-------------
Media Queries
--------------*/
@media only screen and (max-width: 840px) {
    .section_wrapper {
        flex-direction: column;
        padding: 15% 10% 0% 10%;
    }
    
    .section_wrapper h2 {
        text-align: center;
    }

    .section_wrapper h3 {
        text-align: center;
    }
    
    .section_wrapper p{
        text-align: center;
}

.cards_wrapper {
    flex-direction: column;
}

.card {
    width: 100%;
    padding: 0px;
    text-align: center;
    margin: 5% 0%;
}

.card img {
    width: 50%;
    height: auto;   
}

.card_content h4 {
   font-weight: 700;
}

.card ul {
    margin-top: 35px;
    font-size: 14px;
}

.werte_content {
    
    flex-direction: column;
   
}

.werte_content {
    flex-direction: column;
    padding-bottom: 10%;
}

.werte_card {
    width: 100%;
    padding: 0px 20px;
    text-align: center;
    margin: 15px 0%;; 
}

.werte_header {
    padding-left: 0px;
}

.werte_header h2 {
    margin-bottom: 0rem;
}


.icon {
    margin: 20px auto;
}


}



@media only screen and (max-width: 501px) {
    .section_wrapper {
        flex-direction: column;
        padding: 15% 10% 0% 10%;
        justify-content: center;
    }
    
    .section_wrapper h2 {
        text-align: center;
        
    }

    .section_wrapper h3 {
        text-align: center;
    }
    
    .section_wrapper p{
        text-align: center;
}

.cards_wrapper {
    flex-direction: column;
}

.card {
    width: 100%;
    padding: 0px;
    text-align: center;
    margin: 10% 0%;
}

.card img {
    width: 60%;
    height: auto;   
}

.card_content h4 {
   font-weight: 700;
}

.card ul {
    margin-top: 35px;
    font-size: 14px;
}

.werte_content {
    flex-direction: column;
    padding-bottom: 10%;
}

.werte_card {
    width: 100%;
    padding: 0px;
    text-align: center;
    margin: 15px 0%;; 
}

.werte_header {
    
    width: 100%;
    text-align: center;
}

.werte_header h2 {
    margin-bottom: 0rem;
    font-size: 1.8rem;
}


.icon {
    margin: 20px auto;
}

}

