/*
---------------------------
---------------------------
Allgemein
---------------------------
---------------------------*/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  }

  p {
    letter-spacing: 1.4px; 
    font-size: 16px;
}

/*
----------
Allgemein Headings
----------*/
h1, h2, h3, h4, h5{
  text-transform: uppercase;
  letter-spacing: 1.3px; 
}

h5, h6 {
letter-spacing: 1.3px;
}

@media screen and (max-width: 480px) {
  h1, h2, h3, h4, h5 {
      font-size: 12px;
      font-weight: 100;
    }
}

@media screen and (max-width: 480px){
  p {
      font-size: 14px;
      
      
    }
}













