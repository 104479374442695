.workshop_wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    

}

.workshop_section {
    background: rgba(0, 0, 0, 1);
    padding: 5% 10%;
    color:rgb(180, 180, 180);

    
}
.workshop_section h2 {
    margin-bottom: 2%;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1.8px;
    color: rgba(235,95,74,0.95);
   
    
}

.workshop_section h3 {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 1.8px;
    margin: 5% 0% 2% 0%;
    color: rgba(235,95,74,0.95);
    
    
}

.workshop_section li {
    list-style: none;
    margin: 0.2rem;
    letter-spacing: 1.5px;
}



.workshop_section_info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
    justify-content: space-between;
    padding: 5% 10%;
    width: 100%;
    height: auto;
    color: white;
    background: linear-gradient(-90deg, rgba(235,95,74,0.7) 0%, rgba(235,95,74,0.8) 45%, rgba(235,95,74,0.9) 100%);
    
}

.workshop_info_icon {
    height: 55px;
    width: 55px;
    border-radius: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5%;
   
}

.workshop_info_icon img {
    width: 50%;
    height: 50%;   
}

.workshop_info_card {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    width: auto;
    margin-bottom: 8%;
    
}


.workshop_info_card p{
    text-align: left;
    font-size: 1.2rem;
}

.workshop_info_side_right {
    width: 60%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
    }


.workshop_info_side_right iframe {
    margin-left: 65px;
   
}

.workshop_section_info p {
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 1.5px;
}
#icon_ort{
    align-items: flex-start;
}

.workshop_section_info a {
    color: black;
    text-decoration: none;
    
    text-transform: uppercase;
}

.workshop_info_side_left {
    width: 40%;
    
}
.workshop_info_side_right iframe {
    
    width: 60%;
    height: 100%;
    }

@media screen and (max-width: 1024px) {
   
    .workshop_info_text p {
        text-align: left;
        font-size: 1.2rem;
    }

    .workshop_info_side_left {
        width: 50%;
        
    }

    .workshop_info_side_right {
        width: 50%;
        
    }
    
    }



@media screen and (max-width: 910px) {
    .workshop_section {
        padding: 15% 13%;
        text-align: center;
        
    }
    .workshop_section h2 {
        margin-bottom: 5%;
        font-size: 1.8rem;
        font-weight: 700;
        }
    
    .workshop_section h3 {
        font-size: 1.3rem;
        font-weight: 600;
        letter-spacing: 1.8px;
        margin: 15% 0% 5% 0%;
    }

    .workshop_section_info {
        flex-direction: column;
        padding-bottom: 15%;
    }

    .workshop_section_info p {
        font-size: 1.3rem;
        text-align: center;
    }

    .workshop_info_text p {
        text-align: left;
        font-size: 1.3rem;
    }

    .workshop_info_card {
        width: 100%;
        justify-content: flex-start;
    }

    .workshop_info_side_left {
        width: 100%;
        
    }

    .workshop_info_side_right {
        width: 100%;
       
        
        }
    
    .workshop_info_side_right iframe {
       margin: 0 auto;
       width: 70%;

        }
    }



@media screen and (max-width: 501px) {
    
    .workshop_section {
        padding: 15% 13%;
        text-align: center;
        
    }
    .workshop_section h2 {
        margin-bottom: 5%;
        font-size: 1.8rem;
        font-weight: 700;
        }
    
    .workshop_section h3 {
        font-size: 1.3rem;
        font-weight: 600;
        letter-spacing: 1.8px;
        margin: 15% 0% 5% 0%;
    }

    .workshop_section_info {
        flex-direction: column;
        padding-bottom: 15%;
        padding-top: 15%;
    }

    .workshop_section_info p {
        font-size: 1.2rem;
        text-align: center;
    }

    .workshop_info_text p {
        text-align: left;
        font-size: 0.8rem;
    }

    .workshop_info_card {
        width: 100%;
        justify-content: flex-start;
    }

    .workshop_info_side_left {
        width: 100%;
    }

    .workshop_info_side_right {
        width: 100%;
        }
    
    .workshop_info_side_right iframe {
        margin: 5% 0%;
        width: 100%;
        height: 100%;
        }
    }

