.video_wrapper {
    display: flex;
    width: 100%;
    height: 100vh;
    background: linear-gradient(-90deg, rgba(235,95,74,0.7) 0%, rgba(235,95,74,0.8) 45%, rgba(235,95,74,0.9) 100%);
    color: white;
    justify-content: center;
    align-items: center;
    
}




@media screen and (max-width: 1024px) {
    .video_wrapper {
        height: 90vh;
        padding-left: 12%;
        padding-right: 12%;
    }
    

}



@media screen and (max-width: 768px) {
    .video_wrapper {
        height: 75vh;
        padding-left: 12%;
        padding-right: 12%;
    }
    

}

@media screen and (max-width: 480px) {
    .video_wrapper {
        height: 65vh;
        padding-left: 15%;
        padding-right: 15%;
    }
    

}