.wrapper_kontakt {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(-90deg, rgba(235,95,74,0.7) 0%, rgba(235,95,74,0.8) 45%, rgba(235,95,74,0.9) 100%);
    height: 100vh;
    color: white;
}

.text_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: 10%;
    text-align: center;
}

.text_wrapper h4 {
  font-weight: 700;
  font-size: 1.8rem;
}

.text_wrapper h5 {
    margin: 3% 0;
    font-size: 1.2rem;
}

.btn {
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-size: inherit;
    color: inherit;
    background: none;
    cursor: pointer;
    padding: 2% 5%;
    margin-top: 2%;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 600;
    outline: none;
    position: relative;
    transition: all 0.3s;
  }
  
  .btn:after {
    content: '';
    position: absolute;
    z-index: -1;
    transition: all 0.3s;
  }
  
  /* Pseudo elements for icons */
  .btn:before {
    font-family: 'FontAwesome';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    position: relative;
    
  }
  
  
  /* Icon separator */
  .btn-sep {
    padding: 2% 6% 2% 10%;
  }
  
  .btn-sep:before {
    background: rgba(0,0,0,0.15);
  }

  .btn-4 {
    background: #191a1b;
    color: #fff;
  }
  
  .btn-4:hover {
    background: #4d4d4d;
  }
  
  .btn-4:active {
    background: #4d4d4d;
    top: 2px;
  }
  
  .btn-4:before {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 2.5;
    font-size: 170%;
    width: 25%;
  }

  .icon-send:before {
    content: "\f1d8";
  }

  
  @media screen and (max-width: 1024px) {
    .wrapper_kontakt {
      height: 80vh;
  }
    .text_wrapper h4 {
    font-weight: 700;
    font-size: 1.5rem;
  }
  
  .text_wrapper h5 {
    margin: 8% 0;
    font-weight: 600;    
  }
  
  .btn-4:before {  
  font-size: 140%;
  line-height: 2.5;
  width: 30%;
  
  }
    
  }
    

@media screen and (max-width: 768px) {
    .wrapper_kontakt {
      height: 80vh;
  }
  .text_wrapper h4 {
    font-weight: 700;
    font-size: 1.2rem;
  }

  .text_wrapper h5 {
    margin: 8% 0;
  font-weight: 600;    
}

.btn-4:before {  
  font-size: 120%;
  line-height: 2;
  width: 30%;
 
}
    
}



@media only screen and (max-width: 501px){
  .wrapper_kontakt {
    height: 80vh;
}
.text_wrapper h4 {
  font-weight: 700;
  font-size: 1.2rem;
}

.text_wrapper h5 {
  margin: 8% 0;
font-weight: 600;    
}

.btn-4:before {  
line-height: 2;
font-size: 100%;
width: 25%;
}
  
}



