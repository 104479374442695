.counter_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    height: 70vh;
    padding: 5% 0px;
    background: linear-gradient(-90deg, rgba(235,95,74,0.7) 0%, rgba(235,95,74,0.8) 45%, rgba(235,95,74,0.9) 100%);
    color: white;
}

.counter {
    display: flex;
    font-size: 1.8rem;
    width: 35%;
    min-height: 35%;
    text-align: center;
    border: 2px solid white;
    border-radius: 6px;
    margin: 10px;
    box-shadow: 0 0 10px rgb(249, 246, 246);
}

.counter_content {
    margin: auto;
    font-weight: 700;
}

.counter_content h2 {
    font-weight: 700;
    letter-spacing: 1.8px;
    font-size: 1.8rem;
}

.disg_section_wrapper {
    display: flex;
    flex-direction: column;
    background-color: black;
    text-align: center;
    padding: 5% 2.5%;
    color: white;
    
}


.disg_section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    color: rgb(180, 180, 180);;
    padding: 2% 0px;
    
}

.disg_section_picture {
    width: 40%;
    height: 100%;
    cursor: pointer;
}

.disg_section_text {
    width: 40%;
    text-align: left;
    letter-spacing: 1.5px;
}

.disg_section_heading h2 {
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: 1.8px;
}

.disg_section_heading h2:after {
        content: "";
        display: block;
        width: 15%;
        height: 2px;
        background-color: rgba(235, 95, 74, 0.9);
        margin: 1.5rem auto; 
}


.disg_section_types {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.disg_type_card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-width: 22%;
    max-width: 30%;
    height: 100%;
    margin: 15px;
    padding: 10px;
    border-radius: 6px;
    color: white;
}


.disg_type_card h5 {
    margin-bottom: 30px;
    padding-top: 25px;
    font-size: 1.5rem;
    font-weight: 700;
}

#green {
    background-color: rgba(14, 150, 123, 0.9);
}

#red {
    background-color: rgba(235, 95, 74, 0.9);
}

#blue {
    background-color: rgba(24, 159, 193, 0.9);
}

#yellow {
    background-color: rgba(248, 203, 102, 0.9);
}

.disg_einsatz_wrapper {
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.85);
    padding: 40px;
    text-align: center;
    align-items: center;

}

.disg_einsatz_heading {
    color: white;
    padding-top: 40px;
    width: 80%;
    
}

.disg_einsatz_heading h2 {
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1.8px;
}

.disg_einsatz {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 55px 0px; 
    color: rgba(235, 95, 74, 0.8);
    
}

.disg_einsatz_card {
    display: flex;
    flex-direction: column;
    width: 23%;
    height: 250px;
    text-align: center;
    margin: 8px;
    border: 2px solid white;
    border-radius: 6px;
    
    
}

.disg_einsatz_card:hover {
    opacity: 0.8;
    background-color: black;
    transition: 0.9s;
    transform: scale(1.05);
}

.disg_einsatz_card h5 {
    margin-top: 20%;
    font-size: 1.1rem;
    font-weight: 700;
}

.disg_einsatz_card_favicon {
   padding-top: 15px;
   padding-left: 15px;
   height: 60px;
   width: 35px;
}

@media screen and (max-width:1300px) {
    .counter_wrapper {
        flex-direction: row;
        align-items: center;
        height: 70vh;
    }

    .counter {
        font-size: 1.5rem;
        width: 35%;
        height: auto;
        margin: 10px;
        padding: 30px 10px;
    }
    
    .counter_content {
        margin: auto;
        font-weight: 700;
    }
    
    .counter_content h2 {
        font-weight: 700;
        font-size: 1.5rem;
    }

    .disg_einsatz {
        flex-direction: row;
        padding: 55px 0px; 
        
    }
    .disg_einsatz_heading h2 {
        font-size: 1.5rem;
        
    }
    
    .disg_einsatz_card {
        flex-direction: column;
        width: 25%;
    }
    
}


@media screen and (max-width:1024px) {
    .counter_wrapper {
        flex-direction: row;
        align-items: center;
        height: 70vh;
    }

    .counter {
        font-size: 1.3rem;
        width: 40%;
        height: auto;
        margin: 10px;
        padding: 30px 10px;
    }
    
    .counter_content {
        margin: auto;
        font-weight: 700;
    }
    
    .counter_content h2 {
        font-weight: 700;
        font-size: 1.3rem;
    }

    .disg_section_heading h2 {
        font-size: 2rem;
        margin-top: 5%;
    }

    .disg_section {
        flex-direction: column;
    }

    .disg_section_picture {
        width: 60%;
        margin: 5% 0%;
    }
    
    .disg_section_text {
        width: 100%;
        text-align: center;
        padding: 5% 10%;
    }

    .disg_section_types {
        flex-direction: column;
    }
    
    .disg_type_card {
        flex-direction: column;
        min-width: 90%;
        height: 100%;
    }
    
    
    .disg_type_card h5 {
        font-size: 1rem;
    }

    .disg_einsatz {
        flex-direction: row;
        padding: 55px 0px; 
        
    }
    .disg_einsatz_heading h2 {
        font-size: 1.5rem;
        
    }
    
    .disg_einsatz_card {
        flex-direction: column;
        width: 30%;
    }

    
}

@media screen and (max-width:800px) {
    .counter_wrapper {
        flex-direction: row;
        align-items: center;
        height: 60vh;
    }

    .counter {
        font-size: 1rem;
        width: 45%;
        height: auto;
        margin: 10px;
        padding: 30px 10px;
    }
    
    .counter_content {
        margin: auto;
        font-weight: 600;
    }
    
    .counter_content h2 {
        font-weight: 600;
        font-size: 1rem;
    }

    .disg_section_heading h2 {
        font-size: 2rem;
        margin-top: 5%;
    }

    .disg_section {
        flex-direction: column;
    }

    .disg_section_picture {
        width: 60%;
        margin: 5% 0%;
    }
    
    .disg_section_text {
        width: 100%;
        text-align: center;
        padding: 5% 10%;
    }

    .disg_section_types {
        flex-direction: column;
    }
    
    .disg_type_card {
        flex-direction: column;
        min-width: 90%;
        height: 100%;
    }
    
    
    .disg_type_card h5 {
        font-size: 1rem;
    }

    .disg_einsatz {
        flex-direction: row;
        padding: 55px 0px; 
        
    }
    .disg_einsatz_heading h2 {
        font-size: 1.5rem;
        
    }
    
    .disg_einsatz_card {
        flex-direction: column;
        width: 35%;
    }

    
}
@media screen and (max-width: 550px) {
    .counter_wrapper {
        flex-direction: row;
        align-items: center;
        height: auto;
    }

    .counter {
        font-size: 0.8rem;
        width: 45%;
        height: 100%;
        margin: 10px;
        padding: 30px 10px;
    }
    
    .counter_content {
        margin: auto;
        font-weight: 600;
    }
    
    .counter_content h2 {
        font-weight: 600;
        font-size: 0.8rem;
    }

    .disg_section_heading h2 {
        font-size: 1.5rem;
        margin-top: 10%;
    }

    .disg_section {
        flex-direction: column;
    }

    .disg_section_picture {
        width: 80%;
        margin: 10% 0%;
    }
    
    .disg_section_text {
        width: 100%;
        text-align: center;
        padding: 5% 10%;
    }

    .disg_section_types {
        flex-direction: column;
    }
    
    .disg_type_card {
        flex-direction: column;
        min-width: 90%;
        height: 100%;
    }
    
    
    .disg_type_card h5 {
        font-size: 1rem;
    }

    .disg_einsatz {
        flex-direction: column;
        padding: 55px 0px; 
        
    }
    .disg_einsatz_heading h2 {
        font-size: 1.5rem;
        
    }
    
    .disg_einsatz_card {
        flex-direction: column;
        width: 100%;
    }

    
}



@media screen and (max-width: 400px) {
    .counter_wrapper {
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .counter {
        font-size: 1rem;
        width: 85%;
        height: 100%;
        margin: 10px;
        padding: 30px 10px;
    }
    
    .counter_content {
        margin: auto;
        font-weight: 600;
    }
    
    .counter_content h2 {
        font-weight: 600;
        font-size: 1rem;
    }
    
    .disg_section_heading h2 {
        font-size: 1.5rem;
        margin-top: 10%;
    }

    .disg_section {
        flex-direction: column;
    }

    .disg_section_picture {
        width: 80%;
        margin: 10% 0%;
    }
    
    .disg_section_text {
        width: 100%;
        text-align: center;
        padding: 5% 10%;
    }

    .disg_section_types {
        flex-direction: column;
    }
    
    .disg_type_card {
        flex-direction: column;
        min-width: 90%;
        height: 100%;
    }
    
    
    .disg_type_card h5 {
        font-size: 1rem;
    }

    .disg_einsatz {
        flex-direction: column;
        padding: 55px 0px; 
        
    }
    .disg_einsatz_heading h2 {
        font-size: 1.5rem;
        
    }
    
    .disg_einsatz_card {
        flex-direction: column;
        width: 100%;
    }
    
}