.story_section_black {
    background-color: rgba(0,0,0,1);
    color: white;
    
  }

  .story_wrapper {
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .story_section_logo {
    height: auto;
    width: 35%;
    color: black;
    display: flex;
    align-items: center;
    opacity: 0.8; 
    filter: grayscale(100%);
   
    max-width: 600px;
   
  }

  .story_section_content{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left:5%;
    text-align: center;
    max-width: 800px;
  }

  .story_section_content_title {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: 1.8px;
  }

  .story_section_content_title:after {
    content: "";
    display: block;
    width: 30%;
    height: 1px;
    background-color: rgb(235, 95, 74);
    margin: 1.5rem auto;
}

.story_section_content p {
  color:rgb(180, 180, 180);

}

.story_button_black {
    width: 150px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    height: 50px;
    text-align:center;
    letter-spacing: 1.2px;
    border: 1px solid white;
    border-radius: 5px;
    background-color: transparent;
    transition: all .4s ease-in-out;
    margin: 40px 0px;
}


.story_button_black:hover {
    border-color: rgb(235, 95, 74);
    color:rgb(235, 95, 74);
    transition: all .4s ease-in-out;
}






@media screen and (max-width: 1024px) {
  .story_wrapper {
    flex-direction: column;
    height: auto;
    padding: 10% 0;
  }

  .story_section_logo {
    width: 40%;
    margin-bottom: 8%;
  }

  .story_section_content{
    width: 90%;
    padding: 0% 5%;
   
  }

  .story_section_content_title {
    font-size: 1.8rem;
    font-weight: 700;
    
  }

  .button_black {
    margin-bottom: 100%;
  }
  
}

@media screen and (max-width: 768px) {
  .story_wrapper {
    flex-direction: column;
    height: auto;
    padding: 10% 0;
  }

  .story_section_logo {
    width: 40%;
    margin-bottom: 8%;
  }

  .story_section_content{
    width: 90%;
    padding: 0% 5%;
  }

  .story_section_content_title {
    font-size: 1.8rem;
    font-weight: 700;
  }

  .button_black {
    margin-bottom: 100%;
  }
  
}

@media screen and (max-width: 501px) {
  .story_wrapper {
    flex-direction: column;
  }

  .story_section_logo {
    width: 70%;
    margin-bottom: 20%;
    margin-top: 10%;
  }

  .story_section_content{
    width: 90%;
    padding: 0% 5%;
  }

  .story_section_content_title h2 {
    font-size: 1.5rem;
    font-weight: 700;
  }

  
}
