
.footer_wrapper {
    background-color: black;

}

.footer {
    font-size: 0.9rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 55px 0px;
    margin-bottom: 0;
    background-color: black;
    color: white;
}

.contact {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
}

.contact p {
    font-size: 14px;
    line-height: 1.3;
    font-style: normal;
}

.contact a {
    text-decoration: none;
    color: white;
}

.contact a:hover {
    color: rgba(235, 95, 74, 0.9);
}

.social {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    margin: auto 0px;
}

.social a {
    padding: 5px;
}

.social_header {
    display: flex;
    color: white;
    font-size: 1rem;
    justify-content: center;
}

.social img {
    height: 50px;
    width: auto;
    background-color: black;
    opacity: 0.8;
}

.about {
    display: flex;
    flex-direction: column;
}

.navbar_link_footer {
    text-decoration: none;
    color: white;
    padding: 0.2rem;
    
}

.navbar_link_footer:hover {
    color: rgba(235, 95, 74, 0.9);
    font-weight: bold;
}


@media screen and (max-width: 600px) {
    
    
    .about {
       display: none;
}


}
    

