.wrapper_DankePage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 80vh;
    padding: 10%;
    background: linear-gradient(-90deg, rgba(235,95,74,0.7) 0%, rgba(235,95,74,0.8) 45%, rgba(235,95,74,0.9) 100%);
    color: white;
}

.headerDanke {
    text-align: center;
    width: 70%;
}

.headerDanke h2 {
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1.8px;
}

.headerDanke h6 {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 1.8px;
    margin-top: 1rem;
    margin-bottom: 5%;
}

@media screen and (max-width: 768px) {
    .headerDanke {
        
        width: 90%;
    }
    .headerDanke h2 {
        font-size: 1.8rem;
    }

    .headerDanke h6 {
        font-size: 1rem;
    }
}

@media screen and (max-width: 501px) {
    .headerDanke {
        
        width: 100%;
    }
    .headerDanke h2 {
        font-size: 1.5rem;
    }

    .headerDanke h6 {
        font-size: 1rem;
    }
}
    




