.wrapper_kontaktPage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 90vh;
    padding: 5%;
    background: linear-gradient(-90deg, rgba(235,95,74,0.7) 0%, rgba(235,95,74,0.8) 45%, rgba(235,95,74,0.9) 100%);
    color: white;
}

.headerKontakt {
    padding: 3%;
    width: 60%;
}

.headerKontakt h2 {
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: 1.8px;
}

.headerKontakt h6 {
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 1.8px;
}

.wrapper_kontaktPage img {
    width: 40%;
    margin-top: 10%;
    opacity: 0.8;
}


.wrapper_formular {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin: 3%;
    padding: 1%;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 10px rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 1);
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    padding: 2%;
}

label {
    width: 100%;
    text-align: left;
    margin-top: 2%;
}

input {
    width: 100%;
    margin: 2% 0;
    height: 35px;
    font-size: 17px;
    outline: none;
    border-radius: 1px;
    border: none;
    background: rgb(235, 228, 228);
}

label {
    color:rgb(180, 180, 180);
}

.name_wrapper {
    display: flex;
    flex-direction: row;
}

.vorname_wrapper {
    flex-direction: column;
    margin-right: 2%;
}

.nachname_wrapper {
    flex-direction: column;
    margin-left: 2%;
}

textarea {
    width: 100%;
    margin: 2% 0;
    font-size: 17px;
    outline: none;
    border-radius: 1px;
    border: none;
    background: rgb(235, 228, 228);
}
input[type=submit]{
    border-radius: 1px;
    padding: 0px 20px;
    margin: 25px auto;
    width: 150px;
    height: 50px;
    font-size: 16px;
    color: #fff;
    background-color: black;
    border: 1px solid white;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 600;
    transition: all 0.3s;
   


}


input[type=submit]:hover{
    color: rgba(235,95,74,0.8);
    border-color: rgba(235,95,74,0.8);
    transform: scale(1.1);
    
}

@media only screen and (max-width:1024px) {
    .wrapper_kontaktPage {
        flex-direction: column;
        height: auto;
        padding: 5%;
    }

    .headerKontakt {
        padding: 10% 5%;
        width: 100%;
        text-align: left;
    }

    .headerKontakt h2 {
        font-size: 1.8rem;
        font-weight: 700;
        letter-spacing: 1.8px;
    }
    
    .headerKontakt h6 {
        margin-top: 5%;
    }

    .wrapper_kontaktPage img {
        display: none;
    }
    
    .wrapper_formular {
        width: 80%; 
        margin-bottom: 10%;   
    }
}




@media only screen and (max-width:768px) {
    .wrapper_kontaktPage {
        flex-direction: column;
        height: auto;
        padding: 5%;
    }

    .headerKontakt {
        padding: 10% 5%;
        width: 100%;
        text-align: center;
    }

    .headerKontakt h2 {
        font-size: 1.8rem;
        font-weight: 700;
        letter-spacing: 1.8px;
    }
    
    .headerKontakt h6 {
        margin-top: 5%;
    }

    .wrapper_kontaktPage img {
        display: none;
    }
    
    .wrapper_formular {
        width: 80%; 
        margin-bottom: 10%;   
    }
}

@media only screen and (max-width:380px) {
    .wrapper_kontaktPage {
        flex-direction: column;
        height: auto;
        padding: 5%;
    }

    .headerKontakt {
        padding: 15% 5%;
        width: 100%;
        text-align: center;
    }

    .headerKontakt h2 {
        font-size: 1.5rem;
        font-weight: 700;
        letter-spacing: 1.8px;
    }
    
    .headerKontakt h6 {
        margin-top: 5%;
    }

    .wrapper_kontaktPage img {
        display: none;
    }
    
    .wrapper_formular {
        width: 100%;    
    }

    .name_wrapper {
        flex-direction: column;
    }
    .vorname_wrapper {
        
        margin-right: 0%;
    }
    
    .nachname_wrapper {
        
        margin-left: 0%;
    }
}


