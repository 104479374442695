.disg_section_black {
    background-color: rgba(0,0,0,1);
    color: white;
    
  }

  .disg_wrapper {
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .disg_section_content{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px 45px;
    text-align: center;
    max-width: 700px;
  }
  
  .disg_section_content_title {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: 1.8px;
  }

  .disg_section_content_title:after {
    content: "";
    display: block;
    width: 30%;
    height: 1px;
    background-color: rgb(235, 95, 74);
    margin: 1.5rem auto;
}

.disg_section_content p {
  color:rgb(180, 180, 180);

}

.disg_button_black {
  width: 150px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  height: 50px;
  text-align:center;
  letter-spacing: 1.2px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: transparent;
  transition: all .4s ease-in-out;
  margin: 40px 0px;
}


.disg_button_black:hover {
  border-color: rgb(235, 95, 74);
  color:rgb(235, 95, 74);
  transition: all .4s ease-in-out;
}

.disg_section_logo {
    height: auto;
    width: 35%;
    color: black;
    display: flex;
    align-items: center;
    opacity: 0.8; 
    filter: grayscale(100%);
    margin: 0 0 0 10%;
   
  }

  #disg_picture {
    height: 100%;
    width: 50%;
    color: black;
    display: flex;
    align-items: center;
    opacity: 0.8; 
    filter: grayscale(100%);
    margin: 0;
    object-fit: fill;
  
  }
  @media screen and (max-width: 960px) {
    .disg_wrapper {
      height: auto;
      flex-direction: column-reverse;
    }

    .disg_section_content{
      width: 100%;
      margin-top: 25px;
    padding: 35px;
      
    }

    .disg_section_content_title {
      font-size: 2.5rem;
     
    }

    #disg_picture {
      width: 100%;
    
    }
  }


  @media screen and (max-width: 890px) {
    .disg_wrapper {
      height: auto;
      flex-direction: column-reverse;
    }

    .disg_section_content{
      width: 100%;
      margin-top: 25px;
    padding: 35px;
      
    }

    .disg_section_content_title {
      font-size: 2rem;
     
    }

    #disg_picture {
      width: 100%;
    
    }
  }

  @media screen and (max-width: 501px) {
    .disg_wrapper {
      height: auto;
      flex-direction: column-reverse;
    }

    .disg_section_content{
      width: 100%;
      
      margin-top: 15px;
    padding: 35px;
      
    }

    .disg_section_content_title {
      font-size: 2rem;
     
    }

    #disg_picture {
      width: 100%;
    
    }
  }